import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {AppContainer} from "../components/styles/AppStyle";
import Loading from "../components/common/Loading";
import GameTable from "../scenes/GameTable";
import Feed from "../scenes/GameTable/Feed";
import MaxxFeed from "../scenes/GameTable/MaxxFeed";
import {ROUTE_API_LOGIN, ROUTE_HOME, ROUTE_LOGOUT, ROUTE_TABLE_LIVE_PLAY} from "./routes";
import AuthRoute from "./AuthRoute";
import ApiLogin from "../scenes/Account/Login/ApiLogin";
import AccountBlock from "../scenes/GameTable/AccountBlock";
import BaseMaster from "../scenes/Public/BaseMaster";
import Home from "../scenes/Public/Home";
import Page from "../scenes/Public/Page";
import UserService from "../services/user.service";

class WebsiteRouter extends Component {
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        let configurations = await UserService.getConfigData();
        let lang = await applicationContext.syncLanguage(configurations['config']['DEFAULT_LANGUAGE']);
        await applicationContext.i18n.changeLanguage(lang);
    }

    render() {
        const {applicationContext, history} = this.props;
        return (
            <React.Fragment>
                <AppContainer direction={applicationContext.translator("direction")}>
                    {applicationContext.state.loading && <Loading />}
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL}/block/account`} render={(props) => (<AccountBlock />)} />
                        <AuthRoute path={ROUTE_TABLE_LIVE_PLAY}  render={(props) => (<GameTable live={true} />)} />
                        <Route path={`${process.env.PUBLIC_URL}/studio9`} render={(props) => (<Feed />)} />
                        <Route path={`${process.env.PUBLIC_URL}/studio1`} render={(props) => (<MaxxFeed />)} />
                        {process.env.REACT_APP_NAME === "swissco.gaming" && <Route path={`${process.env.PUBLIC_URL}/`} render={(props) => (<GameTable />)} />}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <Route path={`${process.env.PUBLIC_URL}/practice`} render={(props) => (<GameTable />)} />}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <Route exact={true} path={"/:slug"} render={(props) => <Page />} />}
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <Route path={`${process.env.PUBLIC_URL}/`} render={(props) => <Home />} />}

                    </Switch>
                </AppContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withRouter(WebsiteRouter));