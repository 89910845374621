import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    PrimaryButton,
    CenterText, SecondaryButton, FlexButtons, SecondaryLink, ColImage
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class FeaturesAndInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"white"}  id={"section-features"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("Features & Interface")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <CenterCol md={6} className={"sm-order-2"}>
                                <CenterText>
                                    <div dangerouslySetInnerHTML={{ __html: applicationContext.translator("Features and interface text") }}></div>
                                    <FlexButtons>
                                        <SecondaryLink target={"_blank"} href={"/assets/files/RouletteTableGuide.pdf"}>{applicationContext.translator("Read More")}</SecondaryLink>
                                        <SecondaryLink target={"_blank"} href={"https://youtu.be/FAZNS98bqTk"}>{applicationContext.translator("View Video")}</SecondaryLink>
                                    </FlexButtons>

                                </CenterText>
                            </CenterCol>
                            <Col md={6} className={"sm-order-1"}>
                                <ColImage><img src={"/assets/img/table-guide.jpg"} width={"90%"}/></ColImage>
                            </Col>

                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(FeaturesAndInterface);