import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {
    SectionContainer,
    SectionTitle,
    SectionBody, CenterCol,
    Brand
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"white"} id={"section-about"}>
                <Container>
                    <SectionTitle><div className={"wrapper"}>{applicationContext.translator("About")}</div></SectionTitle>
                    <SectionBody>
                        <Row>
                            <Col md={8} dangerouslySetInnerHTML={{__html: applicationContext.translator("About us text")}} >
                            
                            </Col>
                            <CenterCol>
                                <div>
                                    <Brand>
                                        <img src={"/assets/img/trust-wallet.jpeg"} />
                                        <h6>{applicationContext.translator("The most trusted & secure crypto wallet")}</h6>
                                    </Brand>
                                    {/*<Brand>*/}
                                    {/*    <img src={"/assets/img/logo.png"} />*/}
                                    {/*    <h6>{applicationContext.translator("Licensed Operator")}</h6>*/}
                                    {/*</Brand>*/}
                                </div>
                            </CenterCol>
                        </Row>
                    </SectionBody>

                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(AboutUs);