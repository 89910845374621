import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withRouter} from "react-router-dom"
import {Formik} from "formik";
import * as Yup from "yup";
import {Alert, Col, Form, FormText, InputGroup, Row} from "react-bootstrap";
import AccountContainer from "../AccountContainer";
import {accountCodeParse, numberWithCommas} from "../../../helpers/helpers";
import LoadingContainer from "../../../components/common/LoadingContainer";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {withAlert} from "react-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./Deposit.module.css";
import DepositService from "../../../services/deposit.service";
import {parse} from "@fortawesome/fontawesome-svg-core";
import Popup from "../../../components/ui/Popup";
class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "0",
            hash_code: "",
            successfully: false,
            submitting: false,
            confirm: false
        }
    }
    handleSubmit = async (values, actions) => {
        const {alert, applicationContext, accountContext} = this.props;
        this.setState({ submitting: true })
        try{
            if(accountContext.userInfo['deposit_limitation']){
                if(accountContext.userInfo['deposit_limitation']['deposit'] + parseFloat(values.amount) > accountContext.userInfo['deposit_limitation']['max']){
                    this.setState({
                        confirm: true,
                        submitting: false
                    })
                    return;
                }
            }

            let result = await DepositService.doDeposit({
                ...values,
                wallet_adr: applicationContext.state.configurations['config']['WALLET_ADDR']
            });
            alert.success(this.props.applicationContext.translator("YOUR DEPOSIT WILL BE TRANSFERED SOON IN TO YOUR ACCOUNT"));
            this.setState({
                successfully: true,
                submitting: false
            })
            actions.resetForm()
        }catch (e){
            this.setState({ submitting: false })
            Object.keys(e.response.data.errors).map((field) => {
                console.log(e.response.data.errors[field][0])
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })

        }


    }
    handleCopyToClipboard = () => {
        const {alert} = this.props;
        alert.success(this.props.applicationContext.translator("The wallet address has been copied."));
    }
    render() {
        const {applicationContext, accountContext} = this.props;
        return (
            <LoadingContainer loading={accountContext.loading}>
                <AccountContainer>
                    <div className={"form-center"}>

                        {!accountContext.loading && <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                amount: Yup.number().required(applicationContext.translator("You must enter amount")).min(1, applicationContext.translator("Amount must be greater than or equal to 1")),
                                hash_code: Yup.string().required(applicationContext.translator("You must enter your hash code"))
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit} className={styles.FullWidth}>
                                    <Form.Group as={Row} >
                                        <Col md={7} className={"text-center"}>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <div className={"text-center mb-1"}>
                                                    {applicationContext.translator("PLEASE TRANSFER YOUR DESIRE AMOUNT TO THIS TETHER (TRC20)  USSD  WALET ADDRESS")}
                                                </div>
                                                <div className={"text-center mb-2 mt-2"}>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"wallet_adr"}
                                                            readOnly={true}
                                                            value={applicationContext.state.configurations['config']['WALLET_ADDR']}
                                                        />
                                                        <InputGroup.Append>
                                                            <CopyToClipboard text={applicationContext.state.configurations['config']['WALLET_ADDR']} onCopy={this.handleCopyToClipboard}>
                                                                <a href={"javascript:void(0)"} className={"btn btn-copy full-width"} ><FontAwesomeIcon icon={"copy"} className={applicationContext.translator("direction") === "rtl"?"ml-1":""} /> {applicationContext.translator("Copy")}</a>
                                                            </CopyToClipboard>
                                                        </InputGroup.Append>
                                                    </InputGroup>

                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={12} className={"end-center-value"}>
                                                    <Form.Text className="text-white" style={{fontSize: "15px", textAlign: "justify"}}>
                                                        {applicationContext.translator("Once you have transferred the amount to the myroulette tether account, enter the same amount together with the hash code and click OK. After approval, from our support (which takes approx 15/30 minutes) refresh the page and the total amount of your deposit will show in the account balance.")}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={3} className={"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("Account ID")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  disabled={true}
                                                                  name={"account_code"}
                                                                  value={accountCodeParse(accountContext.userInfo['account_code'], applicationContext.translator("direction") === "rtl")}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.amount && "mb-3"}>
                                                <Col md={3} className={"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("Deposit Amount")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <InputGroup >
                                                        <Form.Control
                                                            aria-describedby="basic-addon2"
                                                            name={"amount"}
                                                            onChange={(e) => {
                                                                if(e.nativeEvent.inputType === "insertFromPaste"){
                                                                    let v = e.target.value;
                                                                    v = e.target.value.replace(".", "");
                                                                    if(e.target.value.includes(",") && parseFloat(e.target.value) !== NaN){
                                                                        v = v.replace(",", ".");
                                                                        v = Math.round(v).toString();
                                                                    }
                                                                    setFieldValue('amount', v)
                                                                }else{
                                                                    if(!e.target.value.includes(",") && !e.target.value.includes("."))
                                                                        handleChange(e);
                                                                }

                                                            }}

                                                            onBlur={(e) => {
                                                                if(!isNaN(e.target.value) && e.target.value !== "")
                                                                    e.target.value = numberWithCommas(parseFloat(e.target.value));
                                                                else
                                                                    e.target.value = 0;
                                                            }}
                                                            value={values.amount}
                                                            className={"yellow-text"}
                                                            isValid={touched.amount && !errors.amount}
                                                            isInvalid={touched.amount && errors.amount}
                                                            autoComplete="off"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>USD</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            {touched.amount && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={3}></Col>
                                                <Col>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.amount}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}
                                            <Form.Group as={Row} className={"mb-3 " + (applicationContext.translator("direction") === "rtl"?"text-right":"text-left")}>
                                                <Col md={3}></Col>
                                                <Col>
                                                    <Form.Text className="text-white">
                                                        {applicationContext.translator("Please round up your deposit, no decimal number. (ex. 150,45 = $151)")}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className={!touched.hash_code && "mb-3"}>
                                                <Col md={3} className={"end-center-value"}>
                                                    <Form.Label>{applicationContext.translator("Hash Code")}</Form.Label>
                                                </Col>
                                                <Col className={"start-center-value"}>
                                                    <Form.Control type="text"
                                                                  name={"hash_code"}
                                                                  onChange={handleChange}
                                                                  value={values.hash_code}
                                                                  isValid={touched.hash_code && !errors.hash_code}
                                                                  isInvalid={touched.hash_code && errors.hash_code}
                                                                  autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {touched.hash_code && <Form.Group as={Row} className={"mb-3"}>
                                                <Col md={3}></Col>
                                                <Col>
                                                    <Form.Text className="text-danger mt-2">
                                                        {errors.hash_code}
                                                    </Form.Text>
                                                </Col>
                                            </Form.Group>}

                                            <Form.Group className={"d-flex justify-content-center"}>
                                                <button className={"btn btn-account full-width"} disabled={this.state.submitting} type={"submit"}>{applicationContext.translator("OK")}</button>
                                            </Form.Group>

                                            {this.state.successfully && <Form.Group as={Row} className={"text-center mt-3"}>
                                                <Form.Text className="text-success mt-2">
                                                    {applicationContext.translator("YOUR DEPOSIT WILL BE TRANSFERED SOON IN TO YOUR ACCOUNT")}
                                                </Form.Text>

                                            </Form.Group>}

                                        </Col>
                                        <Col md={5} className={"text-center"}>
                                            <div className={"text-center mb-3"}>
                                                <img src={applicationContext.state.configurations['config']['WALLET_IMG']} width={"80%"}/>
                                            </div>
                                        </Col>



                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>}
                    </div>
                    {accountContext.userInfo['deposit_limitation'] && <Popup
                        show={this.state.confirm}
                        onClose={() => this.setState({ confirm: false, disabled: false })}
                        onSubmit={() => this.setState({ confirm: false, disabled: false })}
                        SubmitCaption={"OK"}
                        title={applicationContext.translator("Deposit Limit")}
                        large={false}
                        description={applicationContext.translator("You have reached your monthly deposit-limit of ${value}").replaceAll("{value}",accountContext.userInfo['deposit_limitation']['max'])}
                    >
                    </Popup>}
                </AccountContainer>
            </LoadingContainer>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(Deposit))));